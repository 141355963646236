<template>
  <section class="pd-20">
    <aPageHeader class="pd-0" sub-title="">
      <div slot="title">
        <h1><aIcon type="calendar" class="gradient" /> Calendário</h1>
      </div>
      <div slot="extra">
        <a-tooltip placement="left">
          <template slot="title"> Ver em lista </template>
          <aButton
            class="mr-10"
            type="primary"
            ghost
            @click="$router.push(`list`)"
          >
            <aIcon type="ordered-list" />
          </aButton>
        </a-tooltip>

        <aButton type="primary" ghost @click="openCreateEventModal = true">
          NOVA ATIVIDADE
        </aButton>
      </div>
    </aPageHeader>

    <aRow v-if="$root.isAdmin()" class="haya-panels mb-20" :gutter="[10, 10]">
      <aCol :span="8">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">R$ {{ event.meta.total_federal }}</div>
          <div class="txt">FEDERAL</div>
          <div class="footer">
            <aRow type="flex" justify="space-between">
              <aCol style="color: rgb(255, 189, 189)">
                Pendente: R$
                {{ event.meta.total_federal_pending }}
              </aCol>
              <aCol style="color: rgb(228, 255, 217)">
                Pago: R$ {{ event.meta.total_federal_paid }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>

      <aCol :span="8">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">R$ {{ event.meta.total_prudente }}</div>
          <div class="txt">PRUDENTE</div>
          <div class="footer">
            <aRow type="flex" justify="space-between">
              <aCol style="color: rgb(255, 189, 189)">
                Pendente: R$
                {{ event.meta.total_prudente_pending }}
              </aCol>
              <aCol style="color: rgb(228, 255, 217)">
                Pago: R$ {{ event.meta.total_prudente_paid }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>

      <aCol :span="8">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">R$ {{ event.meta.total_bandeirantes }}</div>
          <div class="txt">BANDEIRANTES</div>
          <div class="footer">
            <aRow type="flex" justify="space-between">
              <aCol style="color: rgb(255, 189, 189)">
                Pendente: R$
                {{ event.meta.total_bandeirantes_pending }}
              </aCol>
              <aCol style="color: rgb(228, 255, 217)">
                Pago: R$
                {{ event.meta.total_bandeirantes_paid }}
              </aCol>
            </aRow>
          </div>
        </div>
      </aCol>
    </aRow>

    <aCollapse
      class="travel-filters expandable mb-20"
      expandIconPosition="right"
      :activeKey="0"
    >
      <aCollapsePanel :key="1">
        <template #header> <aIcon type="filter" /> FILTRAR </template>

        <aRow class="mt-0 mb-0" :gutter="20">
          <aCol :span="4">
            <div class="travel-input">
              <label class="filled" for>Nome</label>
              <a-input
                placeholder="Escreva aqui"
                v-model="event.filters.searchTerm"
                @change="filtersChange()"
              />
            </div>
          </aCol>

          <aCol :span="8">
            <div class="travel-input">
              <label class="filled" for>Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="event.filters.status.selected"
                show-search
                style="width: 100%"
                @change="filtersChange()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="3">
            <div class="travel-input">
              <label class="filled" for>Prioridade</label>
              <a-select
                class="travel-input"
                placeholder="Selecione "
                mode="multiple"
                v-model="event.filters.priority.selected"
                show-search
                style="width: 100%"
                @change="filtersChange()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.priority.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="4">
            <div class="travel-input">
              <label class="filled" for>Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="event.filters.users.selected"
                show-search
                style="width: 100%"
                @change="filtersChange()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.users.list"
                  :key="index"
                  :value="item.id"
                  :txt="item.label"
                >
                  {{ item.id }} - {{ item.first_name }}
                  {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="5">
            <div class="travel-input">
              <label class="filled" for>Valor (R$)</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  style="width: 45%; text-align: center; border-radius: 0"
                  placeholder="Min."
                  v-model="event.filters.price.min"
                  @change="filtersChange()"
                />
                <a-input
                  style="
                    width: 10%;
                    border-left: 0;
                    pointer-events: none;
                    background-color: #fff;
                    border: 0;
                  "
                  placeholder="até"
                />
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="event.filters.price.max"
                  @change="filtersChange()"
                />
              </a-input-group>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="3">
            <div class="travel-input">
              <label class="filled" for>ID Voyage</label>
              <a-input
                placeholder="Escreva aqui"
                v-model="event.filters.voyageId"
                @change="filtersChange()"
              />
            </div>
          </aCol>

          <aCol :span="6">
            <div class="travel-input">
              <label class="filled" for>Tipo</label>
              <a-select
                class="travel-input"
                placeholder="Selecione "
                mode="multiple"
                v-model="event.filters.type.selected"
                show-search
                style="width: 100%"
                @change="filtersChange()"
              >
                <a-select-option
                  v-for="(item, index) of event.filters.type.list"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="3">
            <div class="travel-input">
              <label for class="filled">Empresas</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                v-model="event.filters.companies"
                show-search
                allow-clear
                style="width: 100%"
                @change="filtersChange()"
              >
                <a-select-option
                  v-for="(item, index) of companies.list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.trading_name }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="3">
            <div class="travel-input">
              <label for class="filled">Data de pagamento/recebimento</label>
              <a-date-picker
                v-model="event.filters.date"
                :format="'DD/MM/YYYY'"
                :value-format="'YYYY-MM-DD'"
                @change="filtersChange()"
              />
            </div>
          </aCol>

          <aCol :span="3">
            <div class="travel-input">
              <label for class="filled">Data de vencimento</label>
              <a-date-picker
                v-model="event.filters.dueDate"
                :format="'DD/MM/YYYY'"
                :value-format="'YYYY-MM-DD'"
                @change="filtersChange()"
              />
            </div>
          </aCol>
        </aRow>
      </aCollapsePanel>
    </aCollapse>

    <aRow :gutter="10">
      <aCol :span="24">
        <aButton @click="calendarNav('prev')" class="mini-calendar-arrow"
          ><aIcon type="left"
        /></aButton>

        <aButton @click="calendarNav('next')" class="mini-calendar-arrow ml-10"
          ><aIcon type="right"
        /></aButton>

        <a-calendar
          @select="selectDate"
          @panelChange="calendarPanelChange"
          @change="calendarChange"
          :value="selectDateValue"
          :mode="event.mode"
          v-if="!loadingCalendar"
        >
          <div slot="dateCellRender" slot-scope="value" class="events">
            <a-tooltip v-for="(item, index) in getListData(value)" :key="index">
              <template slot="title">
                {{ item.type }} - {{ item.name }}
                <span v-if="item.type == 'Férias'"
                  >({{ formatDate(item.date) }} a
                  {{
                    item.end_date != "0000-00-00"
                      ? formatDate(item.end_date)
                      : ""
                  }})</span
                >
              </template>
              <div
                class="event-day"
                :txt="item.status"
                :type="item.type"
                :style="'color:' + item.color"
              >
                ●
                {{ item.type == "Férias" ? "Início" : item.type }}
                - {{ item.name }}
                <span v-if="item.type == 'Férias'"
                  >({{ formatDate(item.date) }} a
                  {{
                    item.end_date != "0000-00-00"
                      ? formatDate(item.end_date)
                      : ""
                  }})</span
                >
              </div>
            </a-tooltip>
          </div>

          <template slot="monthCellRender" slot-scope="value">
            <div v-if="getMonthData(value)" class="notes-month">
              <a-tooltip
                v-for="(item, index) in getMonthData(value)"
                :key="index"
              >
                <template slot="title">
                  {{ item.type }} - {{ item.name }}
                </template>
                <div class="event-day" :txt="item.status" :type="item.type">
                  ● {{ item.type }} - {{ item.name }}
                  <span v-if="item.type == 'Férias'"
                    >({{ formatDate(item.date) }} a {{ item.end_date }})</span
                  >
                </div>
              </a-tooltip>
            </div>
          </template>
        </a-calendar>
        <div v-else>
          <a-skeleton active />
          <a-skeleton active />
          <a-skeleton active />
          <a-skeleton active />
        </div>
      </aCol>
    </aRow>

    <aDrawer
      class="create-event"
      :visible="openEventsModal"
      :footer="false"
      @close="closeDate"
      width="1140px"
    >
      <template slot="title">
        <div class="upper">
          <aIcon type="calendar" class="mr-5 cprimary" />
          {{ selectedDate }}
        </div>
      </template>

      <div v-if="event.list.length">
        <aRow v-if="$root.isAdmin()" class="haya-panels mt-20" :gutter="20">
          <aCol :span="8">
            <div class="box">
              <div class="title"></div>
              <div class="total cblack">R$ {{ event.meta.total_federal }}</div>
              <div class="txt">FEDERAL</div>
              <div class="footer">
                <aRow type="flex" justify="space-between">
                  <aCol style="color: rgb(255, 189, 189)">
                    Pendente: R$
                    {{ event.meta.total_federal_pending }}
                  </aCol>
                  <aCol style="color: rgb(228, 255, 217)">
                    Pago: R$
                    {{ event.meta.total_federal_paid }}
                  </aCol>
                </aRow>
              </div>
            </div>
          </aCol>

          <aCol :span="8">
            <div class="box">
              <div class="title"></div>
              <div class="total cblack">R$ {{ event.meta.total_prudente }}</div>
              <div class="txt">PRUDENTE</div>
              <div class="footer">
                <aRow type="flex" justify="space-between">
                  <aCol style="color: rgb(255, 189, 189)">
                    Pendente: R$
                    {{ event.meta.total_prudente_pending }}
                  </aCol>
                  <aCol style="color: rgb(228, 255, 217)">
                    Pago: R$
                    {{ event.meta.total_prudente_paid }}
                  </aCol>
                </aRow>
              </div>
            </div>
          </aCol>

          <aCol :span="8">
            <div class="box">
              <div class="title"></div>
              <div class="total cblack">
                R$ {{ event.meta.total_bandeirantes }}
              </div>
              <div class="txt">BANDEIRANTES</div>
              <div class="footer">
                <aRow type="flex" justify="space-between">
                  <aCol style="color: rgb(255, 189, 189)">
                    Pendente: R$
                    {{ event.meta.total_bandeirantes_pending }}
                  </aCol>
                  <aCol style="color: rgb(228, 255, 217)">
                    Pago: R$
                    {{ event.meta.total_bandeirantes_paid }}
                  </aCol>
                </aRow>
              </div>
            </div>
          </aCol>
        </aRow>

        <div class="a-right mt-15 mb-10">
          <aBadge class="mr-10" :count="selectedInvoices.length">
            <aButton
              class="f13"
              :disabled="selectedInvoices.length > 1 ? false : true"
              @click="openMergeInvoicesModal = true"
              >Mesclar faturas</aButton
            >
          </aBadge>

          <aButton
            type="primary"
            ghost
            class="f13"
            @click="openCreateEventModal = true"
          >
            Cadastrar atividade
          </aButton>
        </div>

        <aTable
          class="travel-table"
          :columns="[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Tipo',
              dataIndex: 'type',
              key: 'type',
            },
            {
              title: 'Nome ',
              dataIndex: 'name',
              key: 'name',
              scopedSlots: { customRender: 'name' },
            },
            this.$root.isAdmin()
              ? {
                  title: 'Empresa',
                  dataIndex: 'company',
                  key: 'company',
                  scopedSlots: { customRender: 'company' },
                }
              : {},
            this.$root.isAdmin()
              ? {
                  title: 'Valor',
                  dataIndex: 'value',
                  key: 'value',
                }
              : {},
            this.$root.isAdmin()
              ? {
                  title: 'Pagamento',
                  dataIndex: 'payment_methods',
                  key: 'payment_methods',
                }
              : {},
            this.$root.isAdmin()
              ? {
                  title: 'Prioridade',
                  dataIndex: 'priority',
                  key: 'priority',
                }
              : {},
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              scopedSlots: { customRender: 'status' },
            },
            {
              title: event.mode == 'year' ? 'Data' : '',
              dataIndex: event.mode == 'year' ? 'date' : 'none',
              key: event.mode == 'year' ? 'date' : 'none',
            },
            {
              title: '',
              key: 'user',
              scopedSlots: {
                customRender: 'user',
              },
            },
            this.$root.isAdmin()
              ? {
                  slots: { title: 'attachmentTitle' },
                  scopedSlots: { customRender: 'attachment' },
                }
              : {},
            {
              title: ``,
              key: 'action',
              align: 'right',
              scopedSlots: { customRender: 'action' },
            },
          ]"
          :data-source="event.list"
          :loading="event.loading"
          :pagination="false"
          :row-selection="{
            onChange: onSelectEventsChange,
          }"
        >
          <span slot="attachmentTitle"><aIcon type="paper-clip" /> </span>
          <a slot="id" slot-scope="text"> {{ text }}</a>

          <div slot="color" slot-scope="record">
            <a-avatar
              shape="circle"
              size="small"
              :style="{
                backgroundColor: record.color,
              }"
            >
            </a-avatar>
          </div>

          <div slot="attachment" slot-scope="record">
            <div v-if="record.attachment">
              <aButton
                @click="openLink(record.attachment)"
                type="link"
                style="font-size: 18px"
              >
                <aIcon type="paper-clip" />
              </aButton>
            </div>
          </div>

          <div slot="user" slot-scope="record">
            <a-tooltip>
              <template slot="title">
                {{ record.user.first_name }}
                {{ record.user.last_name }}
              </template>
              <span id="avatar">
                <a-avatar
                  v-if="record.user.avatar"
                  :src="record.user.avatar"
                  :size="30"
                />
                <a-avatar
                  v-else
                  :size="30"
                  style="color: #f56a00; background-color: #fde3cf"
                >
                  {{ record.user.first_name.substring(0, 1)
                  }}{{ record.user.last_name.substring(0, 1) }}
                </a-avatar>
              </span>
            </a-tooltip>
          </div>
          <div slot="company" slot-scope="record">
            {{ record.trading_name }}
          </div>
          <div slot="bank" slot-scope="record">
            {{ record.name }}
          </div>
          <template slot="action" slot-scope="record">
            <div class="actions-outer" style="position: relative">
              <a-tooltip placement="top" title="Editar">
                <a class="edit ml-15" @click="editEvent(record.id)">
                  <aIcon type="edit-svg" />
                </a>
              </a-tooltip>
            </div>
          </template>
        </aTable>

        <div class="a-center mt-30" style="padding-bottom: 100px">
          <a-pagination
            show-size-changer
            :default-current="event.pagination.page"
            :total="event.pagination.total"
            @change="changeEventsPage"
            @showSizeChange="changeEventsPageSize"
          />
        </div>
      </div>

      <div v-if="event.list">
        <div class="a-center mb-20" v-if="event.list.length == 0">
          <a-result
            status="info"
            title="NENHUMA ATIVIDADE"
            sub-title="Clque no botão abaixo para cadastrar atividade para  este dia."
          >
            <template #extra>
              <aButton
                type="primary"
                ghost
                @click="openCreateEventModal = true"
              >
                CADASTRAR ATIVIDADE
              </aButton>
            </template>
          </a-result>
        </div>
      </div>
    </aDrawer>

    <aDrawer
      class="create-event"
      :visible="openCreateEventModal"
      :footer="false"
      @close="openCreateEventModal = false"
      width="800px"
    >
      <template slot="title">
        <aIcon type="calendar" class="mr-5 cprimary" /> NOVA ATIVIDADE
      </template>
      <EditEventDefaultData
        v-if="openCreateEventModal"
        :date="formatDateModel(selectedDate, 'yyyy-MM-dd', '/')"
        @listEvents="listEvents"
      />
    </aDrawer>

    <aDrawer
      :visible="openEditEventModal"
      :footer="false"
      @close="closeEditEventModal"
      width="800px"
    >
      <template slot="title">
        <aIcon type="calendar" class="mr-5 cprimary" />
        {{ theEvent.id }} - {{ theEvent.name }} ({{ theEvent.type }})
      </template>

      <a-tabs type="card" default-active-key="1" style="margin-top: -10px">
        <aTabPane key="1" tab="DADOS" force-render>
          <EditEventDefaultData
            class="mt-10"
            v-if="openEditEventModal"
            :edit="true"
            :event="theEvent"
            @listEvents="listEvents"
          />
        </aTabPane>
        <aTabPane key="2" force-render>
          <template slot="tab"> RECORRÊNCIA </template>
          Este {{ theEvent.type }} é recorrente? Selecione os dias que ele deve
          ser criado com os mesmos dados do
          <b>{{ theEvent.type }} ID {{ theEvent.id }} - {{ theEvent.name }}</b>
          abaixo:
          <br />
          <br />

          A data atual é
          <b>{{ theEvent.date ? formatDate(theEvent.date) : "" }}</b
          >.

          <div class="multi-select-calendar mt-20">
            <m-date-picker
              v-model="recurringDates"
              :disp="[
                'D',
                'S',
                'T',
                'Q',
                'Q',
                'S',
                'S',
                '',
                '',
                'CANCELAR',
                'OK',
                'LIMPAR',
              ]"
              :multi="multi"
              :always-display="true"
              :format="formatMultiDate"
            ></m-date-picker>
          </div>

          <center v-if="recurringDates.length > 0">
            <a-popconfirm
              placement="top"
              ok-text="Sim"
              cancel-text="Não"
              @confirm="createRecurringDates"
            >
              <template slot="title">
                Tem certeza que deseja criar as datas recorrentes?
              </template>
              <aButton
                size="large"
                type="primary"
                :loading="loadingRecurringDates"
              >
                CRIAR DATAS RECORRENTES
              </aButton>
            </a-popconfirm>
          </center>
        </aTabPane>
        <aTabPane key="3" force-render>
          <template slot="tab"> NOTAS </template>
          <Notes module="event" :moduleId="theEvent.id" />
        </aTabPane>
      </a-tabs>
    </aDrawer>

    <aDrawer
      :visible="openEditEventModal"
      :footer="false"
      @close="closeEditEventModal"
      width="800px"
    >
      <template slot="title">
        <aIcon type="calendar" class="mr-5 cprimary" />
        {{ theEvent.id }} - {{ theEvent.name }} ({{ theEvent.type }})
      </template>

      <aTabs
        v-if="openEditEventModal"
        type="card"
        default-active-key="1"
        style="margin-top: -10px"
      >
        <aTabPane key="1" tab="Dados" force-render>
          <EditEventDefaultData
            class="mt-10"
            v-if="openEditEventModal"
            :edit="true"
            :event="theEvent"
            @listEvents="listEvents"
          />
        </aTabPane>

        <aTabPane key="2" force-render>
          <template slot="tab"> Recorrência </template>
          Este {{ theEvent.type }} é recorrente? Selecione os dias que ele deve
          ser criado com os mesmos dados do
          <b>{{ theEvent.type }} ID {{ theEvent.id }} - {{ theEvent.name }}</b>
          abaixo:
          <br />
          <br />

          A data atual é
          <b>{{ theEvent.date ? formatDate(theEvent.date) : "" }}</b
          >.

          <div class="multi-select-calendar mt-20">
            <m-date-picker
              v-model="recurringDates"
              :disp="[
                'D',
                'S',
                'T',
                'Q',
                'Q',
                'S',
                'S',
                '',
                '',
                'CANCELAR',
                'OK',
                'LIMPAR',
              ]"
              :multi="multi"
              :always-display="true"
              :format="formatMultiDate"
            ></m-date-picker>
          </div>

          <div class="a-center" v-if="recurringDates.length > 0">
            <a-popconfirm
              placement="top"
              ok-text="Sim"
              cancel-text="Não"
              @confirm="createRecurringDates"
            >
              <template slot="title">
                Tem certeza que deseja criar as datas recorrentes?
              </template>
              <aButton type="primary" :loading="loadingRecurringDates">
                CRIAR DATAS RECORRENTES
              </aButton>
            </a-popconfirm>
          </div>
        </aTabPane>

        <aTabPane key="3" force-render>
          <template slot="tab"> Notas </template>
          <Notes module="event" :moduleId="theEvent.id" />
        </aTabPane>

        <aTabPane key="4" force-render>
          <template slot="tab"> Arquivos </template>
          <FilesTable
            moduleName="event"
            :moduleId="theEvent.id"
            :searchModuleIds="theEvent.id"
            searchModules="event"
            s3Folder="events"
          />
        </aTabPane>

        <aTabPane v-if="theEvent.raw.invoice_id != 0" key="5">
          <template slot="tab"> Paxes da Fatura </template>
          <EventInvoiceSalesTab :event="theEvent" />
        </aTabPane>
      </aTabs>
    </aDrawer>

    <aDrawer
      :footer="false"
      :width="$root.windowWidth > 960 ? '1180px' : '100%'"
      :visible="openMergeInvoicesModal"
      @close="openMergeInvoicesModal = false"
    >
      <template #title> MESCLAR FATURAS </template>
      <MergeEventsModal
        v-if="openMergeInvoicesModal"
        :event="event"
        :selectedInvoices="selectedInvoices"
        @onFinishMergeActions="onFinishMergeActions"
      />
    </aDrawer>
  </section>
</template>

<script>
import { format } from "date-fns";
import eventsMixins from "@/mixins/events/eventsMixins.js";
import calendarMixins from "@/mixins/events/calendarMixins.js";
import formatThings from "@/mixins/general/formatThings";
import EditEventDefaultData from "@/components/calendar/forms/EditEventDefaultData.vue";
import EventInvoiceSalesTab from "@/components/calendar/tabs/EventInvoiceSalesTab.vue";
import Notes from "@/components/general/Notes.vue";
import MergeEventsModal from "@/components/calendar/modals/MergeEventsModal.vue";
import FilesTable from "@/components/general/FilesTable.vue";
import companyMixins from "@/mixins/companies/companyMixins";

export default {
  name: "Calendar",
  components: {
    EditEventDefaultData,
    EventInvoiceSalesTab,
    MergeEventsModal,
    Notes,
    FilesTable,
  },
  mixins: [eventsMixins, calendarMixins, formatThings, companyMixins],
  data() {
    return {
      mode: "calendar",
      multi: true,
      recurringDates: [],
      recurringDatesData: {},
      openCreateEventModal: false,
      openEventsModal: false,
      openEditEventModal: false,
      loadingRecurringDates: false,
      openMergeInvoicesModal: false,
      selectedDate: "",
      selectDateValue: format(new Date(), "yyyy-MM-dd"),
      theEvent: {},
    };
  },
  beforeMount() {
    this.initializeEventFilters();
    this.fetchEventFilters();
    this.fetchEventTypes();
    this.fetchEvents();
    this.handleUrlParams();
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    initializeEventFilters() {
      this.event.filters.date = "";
      this.event.type = "calendar";
      this.event.filters.month = new Date().getMonth() + 1;
      this.event.filters.year = new Date().getFullYear();
      this.event.pagination.perPage = 500;
      this.event.filters.orderBy = "status";
    },
    fetchEventFilters() {
      this.loadingCalendar = true;
      this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
        this.event.filters.users.list = data.data;
      });
    },
    fetchEventTypes() {
      if (this.$root.isAdmin()) {
        this.$http
          .get(
            `/category-entry/list?page=1&per_page=10&category_id=13&order-by=name&order=ascend`
          )
          .then(({ data }) => {
            this.event.filters.type.list = data.data;
          });
      } else {
        this.event.filters.type.list = [{ name: "Evento Pessoal" }];
      }
    },
    fetchEvents() {
      this.getEvents();
    },
    handleUrlParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const objectId = urlParams.get("oid");
      if (objectId) {
        this.editEvent(objectId);
      }
    },
    onFinishMergeActions() {
      this.openMergeInvoicesModal = false;
      this.selectedInvoices = [];
      this.selectedRowKeys = [];
      this.fetchEvents();
    },
  },
};
</script>

<style lang="sass">
.multi-select-calendar
  .cal-select
    background-color: #c84779
    color: #FFF
    border-radius: 28px
  .sel-values
    background-color: rgb(200, 71, 121)
    color: #fff
    border-color: rgba(32,160,255,.2)
    font-size: 11px
  .pick-panel
    width: 100%
    border: 0
    box-shadow: none
    margin: 25px 0 5px 0
  .dp-footer
    width: 100%
    padding: 8px 0
    margin: 8px 0 0 0
    border-top: 1px solid #ebebeb
  .selected-date
    min-width: 100%
    padding: 3px 2px
    border: 1px solid #ddd
    max-width: 100%

.ant-fullcalendar-next-month-btn-day,
.ant-fullcalendar-last-month-cell
  background: #fafafa
  opacity: 0.8

.calendar-arrow
  height: 794px !important

.event-day
  background: #fff
  border: 1px solid #ddd
  color: rgba(0, 0, 0, 0.5)
  //width: 100%
  margin: 3px
  padding: 1px 5px
  border-radius: 4px
  font-size: 10px
  font-weight: 600
  line-height: 13px
  display: inline-block

.event-day[type="Recebimento"][txt="Pendente"]
  background: #6A50A7
  border: 1px solid transparent

.event-day[txt="Cancelado"]
  background: #ff7f7e
  border: 1px solid transparent

.event-day[txt="Pendente"]
  background: #ffc87e
  border: 1px solid transparent

.event-day[txt="Pago"]
  background: #9ad9a1
  border: 1px solid transparent

.event-day[txt="Recebido"]
  background: #4A89DC
  border: 1px solid transparent

.event-day[type="Férias"]
  background: #c69ddd
  border: 1px solid transparent

.event-day[txt="Realizado"]
  background: #9ad9a1
  border: 1px solid transparent


.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner
  text-transform: uppercase !important
  font-weight: 600 !important
  letter-spacing: -1px !important

.ant-fullcalendar-fullscreen .ant-select-selection--single
  border: 0 !important
  border-bottom: 2px solid #d9d9d9 !important
  border-radius: 0
  text-align: center
  text-transform: uppercase

.ant-fullcalendar-selected-day
  .ant-fullcalendar-value
    color: #444

.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date
    background: #ffffff !important
    border-color: #ddd

.ant-fullcalendar-last-month-cell .ant-fullcalendar-value
  color: #eee !important

.ant-fullcalendar-value
  font-weight: 600
  font-size: 14px
  text-transform: uppercase
  color: #ccc !important

.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date
  background: transparent
  border-top: 2px solid #ebebeb !important

.ant-fullcalendar-fullscreen .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-date
  margin: 0 !important
  border-width: 1px !important


.ant-select-selection-selected-value
  max-width: 100%
  width: 100%
</style>
